import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $

@Component({
  selector: 'app-community-list',
  templateUrl: './community-list.component.html',
  styleUrls: ['./community-list.component.css']
})
export class CommunityListComponent implements OnInit {
  currTab: any = 'English';
  faqData: any = [];
  pageNumber: number = 1
  faqId: any;
  isPublished: any;
  deleted: any;

  lan: any;
  temp = "http://www.africau.edu/images/default/sample.pdf"
  constructor(public route: Router, public service: ServiceService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.faqList()
  }
  // faq english
  faqList() {
    this.service.showSpinner();
    var url = `static/get-link-list`;
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.faqData = res.data;

      }
      this.service.hideSpinner();
    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  isAssending: boolean = true;

  sortAgent(key) {
    if (this.isAssending) {
      this.faqData = this.faqData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.faqData = this.faqData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  // add faq
  addFaq(tab) {
    this.currTab = tab
    this.route.navigate(['/add-faq'], { queryParams: { tab: tab } })
  }
  // edit faq
  editFaq(communityId) {
    this.route.navigate(['/community-edit'], { queryParams: { communityId: communityId } })
  }
  viewFaq(faqId, tab) {
    this.route.navigate(['/view-faq'], { queryParams: { faqId: faqId, lan: tab } })
  }
  // open modal
  publish(publish, faqId, tab) {
    this.faqId = faqId;
    this.isPublished = publish;
    this.lan = tab
    console.log('gff', this.isPublished);

    $('#publishModal').modal('show')

  }

  // publish faq english
  publishFaq() {
    let url = "static/publish-unpuqblish-faq?faqId=" + this.faqId;
    let data = {}
    this.service.showSpinner();
    this.service.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        $('#publishModal').modal('hide')
        this.service.toasterSucc(res.message);
        this.faqList();
      }
    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  edit() { }
  view() { }

  id: number;
  //========modal=======//
  delete(id: number) {
    this.id = id;
    $("#deleteModal").modal("show");
  }
  deleteUser() {
    this.service
      .delete(`static/delete-link?linkId=${this.id}`)
      .subscribe(
        (res: any) => {
          if ((res.ststus = 200)) {
            $("#deleteModal").modal("hide");
            this.faqList();
          }
        },
        (err) => {
          if (err["status"] == "401") {
            this.service.onLogout();
            this.service.toasterErr("Unauthorized Access");
          } else {
            this.service.toasterErr("Something Went Wrong");
          }
        }
      );
  }
  satus: any
  Sid: any
  openModal(status, id) {
    this.satus = status
    this.Sid = id
    if (status == 'BLOCK') {
      $("#block").modal("show");

    } else {
      $("#active").modal("show");

    }

  }
  blockSocial() {
    this.service
      .post(`static/block-or-unBlock-link?Status=${this.satus}&linkId=${this.Sid}`, {})
      .subscribe(
        (res: any) => {
          if ((res.ststus = 200)) {
            $("#block").modal("hide");
            $("#active").modal("hide");
            this.faqList();
          }
        },
        (err) => {
          if (err["status"] == "401") {
            this.service.onLogout();
            this.service.toasterErr("Unauthorized Access");
          } else {
            this.service.toasterErr("Something Went Wrong");
          }
        }
      );
  }
}
