import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any
@Component({
  selector: 'app-list-partnership',
  templateUrl: './list-partnership.component.html',
  styleUrls: ['./list-partnership.component.css']
})
export class ListPartnershipComponent implements OnInit {

  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  partnershipId: any;

  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';

  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getEmbellishmentList();
    console.log(this.today)
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getEmbellishmentList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getEmbellishmentList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  // -------------------- get category list --------------------- //
  getEmbellishmentList() {
    let apiReqUrl: any = `static/get-partner-list`
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data ? res.data : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    },(err)=>{
        this.commonService.hideSpinner();
      if(err.status == 404){
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
      
    })
  }

  pagination(event) {
    this.currentPage = event;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/add-partner'])
  }
  ParnterDetailsAdd(){
    this.router.navigate(['/partner/Partnership'])

  }
  // view category
  viewBody(partnershipId) {
    this.router.navigate(['/view-partner'], { queryParams: { partnershipId: partnershipId } })
  }
 
  // edit category
  editBody(partnershipId) {
    this.router.navigate(['/edit-partner'], { queryParams: { partnershipId: partnershipId } })
  }
  
  // ------------------------------- delete hospital ----------------------------- //
  deleteCategoryModal(partnershipId) {
    $('#deleteCategory').modal('show')
    this.partnershipId = partnershipId
  }
  deleteCategory() {
    let apiReqUrl: any = "static/delete-partner?partnerid="+this.partnershipId
    this.commonService.showSpinner();
    this.commonService.delete(apiReqUrl).subscribe((res: any) => {
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.getEmbellishmentList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }

}
