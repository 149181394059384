import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-view-team',
  templateUrl: './view-team.component.html',
  styleUrls: ['./view-team.component.css']
})
export class ViewTeamComponent implements OnInit {

  projectTeamId: any;
  contentId: any;
  skirtListArray: any;
  colorList: any = [];
  editData: any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.projectTeamId = res.projectTeamId;
    })
    this.viewBlog();
  }

  // edit faq english
  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.get("static/getById?projectTeamId=" + this.projectTeamId).subscribe((res: any) => {
      if (res.status = 200) {
        console.log('jjh', res);
        this.editData = res.data;
        this.commonService.hideSpinner()
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }




}
