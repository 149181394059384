import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-edit-xindia-table',
  templateUrl: './edit-xindia-table.component.html',
  styleUrls: ['./edit-xindia-table.component.css']
})
export class EditXindiaTableComponent implements OnInit {
  editData:any

  addSwatchesForm: FormGroup
  imageUrl
  coinName:any
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  holdingId:any
  constructor(private router: Router, public commonService: ServiceService,private httpClient:HttpClient,public active:ActivatedRoute) { 
    this.active.queryParams.subscribe((res) => {
      this.holdingId = res.holdingId;
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.viewBlog()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'xindiaHolding': new FormControl('', [Validators.required]),
      'tradingFeesDiscount': new FormControl('', [Validators.required]),
      // 'otcDiscount': new FormControl('', [Validators.required]),
      'privilege': new FormControl('', [Validators.required]),
    })
  }
  addSwatches() {
    let url = `static/admin/update-HoldingXindia?holdListxindiaId=${this.holdingId}`
    let data = {
      'holding': this.addSwatchesForm.value.xindiaHolding,
      'tradingFee': this.addSwatchesForm.value.tradingFeesDiscount,
      'xindiaOtc': 'string',
      'otherPrivilege': this.addSwatchesForm.value.privilege,
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner()
        this.router.navigate(['/xindia-details-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }

  viewBlog(){
    this.commonService.showSpinner()
  this.commonService.get("static/admin/get-holdingXindia-by-Id?holdingxindiaId="+this.holdingId).subscribe((res:any)=>{
    if (res.status=200) {
      this.editData=res.data;
      this.commonService.hideSpinner()
  this.addSwatchesForm.patchValue({
    xindiaHolding : this.editData.holding,
    tradingFeesDiscount : this.editData.tradingFee,
    otcDiscount : this.editData.xindiaOtc,
    privilege : this.editData.otherPrivilege
  })
    }
   },err=>{
  
     this.commonService.hideSpinner();
     if(err['status']=='401'){
       this.commonService.toasterErr('Unauthorized Access');
     }else{
     this.commonService.toasterErr('Something Went Wrong');
  }
   })
  
  } 
}
