import { BnNgIdleService } from 'bn-ng-idle';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-nominee-view',
  templateUrl: './nominee-view.component.html',
  styleUrls: ['./nominee-view.component.css']
})
export class NomineeViewComponent implements OnInit {
  nomineeId: any;
  ticketDetails: any
  userId:any
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public service: ServiceService) {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.nomineeId = res.id
      this.userId =res.userId
    })
  }

  ngOnInit() {
    this.getTicketDetails()
  }

  getTicketDetails() {
    this.service.showSpinner();
    this.service.get(`account/get-details-by-nomineeId?nomineeId=${this.nomineeId}`).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.ticketDetails = res['data']
        this.service.toasterSucc(res['message']);
      } else {
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err['message'])
    })
  }

  back() {
    this.router.navigate(['/nominee'])
  }


}
