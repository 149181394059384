import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-xindia-burnedstatus',
  templateUrl: './xindia-burnedstatus.component.html',
  styleUrls: ['./xindia-burnedstatus.component.css']
})
export class XindiaBurnedstatusComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  coinName:any
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService,private httpClient:HttpClient,public active:ActivatedRoute) { 
    this.active.queryParams.subscribe((res:any)=>{
      this.coinName=res.coinName;
    }) }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getXindiaData()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'burned': new FormControl('', [Validators.required]),
      'circulatingSupply': new FormControl('', [Validators.required]),
      'pendindBurned': new FormControl('', [Validators.required]),
    })
  }
  addSwatches() {
    // let url = `static/admin/add-Xindia-Burned`
    let url = `static/admin/update-Xindia-burned?burnedid=${this.xindiaId}`
    // let url = `static/admin/add-hold-Xindia`
    let data = {
      // 'coinImage': this.imageUrl,
      'burned': this.addSwatchesForm.value.burned,
      'pendindBurned': this.addSwatchesForm.value.pendindBurned,
      'circulatingSupply': this.addSwatchesForm.value.circulatingSupply,
    }
    // this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.getXindiaData()
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner();

        this.router.navigate(['/xindia-details-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  xindiaArray:any = []
  xindiaId:any  []
getXindiaData(){
  let url = `static/get-Xindia-burned-list`
  // this.commonService.showSpinner();
  this.commonService.get(url).subscribe((res: any) => {
    console.log("add category response ==>", res)
    if (res.status == 200) {
      this.xindiaArray = res['data'][0]
      this.xindiaId = res['data'][0]['burnedId']
      // this.commonService.toasterSucc(res.message);
      this.addSwatchesForm.patchValue({
        burned: this.xindiaArray.burned,
        pendindBurned : this.xindiaArray.pendindBurned,
        circulatingSupply : this.xindiaArray.circulatingSupply,
      })
      // this.imageUrl = this.xindiaArray.coinImage
      this.commonService.hideSpinner()
      // this.router.navigate(['/fee-management'])
    } else {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(res.message)
    }
  })
}

}
