import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-withdraw',
  templateUrl: './view-withdraw.component.html',
  styleUrls: ['./view-withdraw.component.css']
})
export class ViewWithdrawComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
