import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-view-blog',
  templateUrl: './view-blog.component.html',
  styleUrls: ['./view-blog.component.css']
})
export class ViewBlogComponent implements OnInit {
  contentId: any;
  skirtListArray: any;
  colorList : any = [];
  editData:any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.contentId = res.contentId;
    })
    this.viewBlog()
  }
  // edit faq english
 viewBlog(){
  this.commonService.showSpinner()
this.commonService.get("static/admin/static-content/get-Blog-by-id?blogId="+this.contentId).subscribe((res:any)=>{
  if (res.status=200) {
    console.log('jjh', res);
    this.editData=res.data;
    this.commonService.hideSpinner()
  }
 },err=>{
   this.commonService.hideSpinner();
   if(err['status']=='401'){
     this.commonService.toasterErr('Unauthorized Access');
   }else{
   this.commonService.toasterErr('Something Went Wrong');
}
 })
} 
}
