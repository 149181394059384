import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-xindia-details-add',
  templateUrl: './xindia-details-add.component.html',
  styleUrls: ['./xindia-details-add.component.css']
})
export class XindiaDetailsAddComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  coinName:any
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService,private httpClient:HttpClient,public active:ActivatedRoute) { 
    this.active.queryParams.subscribe((res:any)=>{
      this.coinName=res.coinName;
    }) }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getXindiaData()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'footer': new FormControl('', [Validators.required]),
      'buy': new FormControl('', [Validators.required]),
      'about': new FormControl('', [Validators.required]),
      'title2': new FormControl('', [Validators.required]),
      'title': new FormControl('', [Validators.required]),
      'coinName': new FormControl('',[Validators.required]),
      'coinImage': new FormControl('')

    })
  }
  addSwatches() {
    // let url = `static/admin/add-Xindia`
    let url = `static/admin/update-Xindia?xindiaId=${this.xindiaId}`
    let data = {
      'coinImage': this.imageUrl,
      'coinName': this.addSwatchesForm.value.coinName,
      'title': this.addSwatchesForm.value.title,
      'title2': this.addSwatchesForm.value.title2,
      'about': this.addSwatchesForm.value.about,
      'buy': this.addSwatchesForm.value.buy,
      'footer' : this.addSwatchesForm.value.footer
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.getXindiaData()
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/xindia-details-list'])
        this.commonService.hideSpinner();

      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  xindiaArray:any = []
  xindiaId:any  []
getXindiaData(){
  let url = `static/get-Xindia`
  this.commonService.showSpinner();
  this.commonService.get(url).subscribe((res: any) => {
    console.log("add category response ==>", res)
    if (res.status == 200) {
      this.xindiaArray = res['data'][0]
      this.xindiaId = res['data'][0]['xindiaId']
      // this.commonService.toasterSucc(res.message);
      this.addSwatchesForm.patchValue({
        coinName: this.xindiaArray.coinName,
        title : this.xindiaArray.title,
        title2 : this.xindiaArray.title2,
        about: this.xindiaArray.about,
        buy: this.xindiaArray.buy,
        footer: this.xindiaArray.footer,
      })
      this.imageUrl = this.xindiaArray.coinImage
      this.commonService.hideSpinner()
      // this.router.navigate(['/fee-management'])
    } else {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(res.message)
    }
  })
}
  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }
}
