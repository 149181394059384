import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-add-xindia-table',
  templateUrl: './add-xindia-table.component.html',
  styleUrls: ['./add-xindia-table.component.css']
})
export class AddXindiaTableComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  coinName:any
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService,private httpClient:HttpClient,public active:ActivatedRoute) { 
    this.active.queryParams.subscribe((res:any)=>{
      this.coinName=res.coinName;
    }) }

  ngOnInit() {
    this.addSwatchesFormValidation();
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'xindiaHolding': new FormControl('', [Validators.required]),
      'tradingFeesDiscount': new FormControl('', [Validators.required]),
      // 'otcDiscount': new FormControl('', [Validators.required]),
      'privilege': new FormControl('', [Validators.required]),
    })
  }
  addSwatches() {
    let url = `static/admin/add-holdingList`
    let data = {
      'holding': this.addSwatchesForm.value.xindiaHolding,
      'tradingFee': this.addSwatchesForm.value.tradingFeesDiscount,
      'xindiaOtc': 'string',
      'otherPrivilege': this.addSwatchesForm.value.privilege,
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner()
        this.router.navigate(['/xindia-details-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
//   xindiaArray:any = []
//   xindiaId:any  []
// getXindiaData(){
//   let url = `static/get-HoldXindia`
//   this.commonService.showSpinner();
//   this.commonService.get(url).subscribe((res: any) => {
//     console.log("add category response ==>", res)
//     if (res.status == 200) {
//       this.xindiaArray = res['data'][0]
//       this.xindiaId = res['data'][0]['holdXindiaId']
//       // this.commonService.toasterSucc(res.message);
//       this.addSwatchesForm.patchValue({
//         tradingFeeDiscount: this.xindiaArray.tradingFeeDiscount,
//         otcDiscount : this.xindiaArray.otcDiscount,
//         toBecome : this.xindiaArray.toBecome,
//         weekly: this.xindiaArray.weekly,
//       })
//       // this.imageUrl = this.xindiaArray.coinImage
//       this.commonService.hideSpinner()
//       // this.router.navigate(['/fee-management'])
//     } else {
//       this.commonService.hideSpinner();
//       this.commonService.toasterErr(res.message)
//     }
//   })
// }
}
