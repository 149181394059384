import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-edit-partnership',
  templateUrl: './edit-partnership.component.html',
  styleUrls: ['./edit-partnership.component.css']
})
export class EditPartnershipComponent implements OnInit {
  addSwatchesForm: FormGroup
  imageUrl
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  editData:any;
  partnershipId:any
  constructor(private router: Router, public commonService: ServiceService, private activatedroute:ActivatedRoute) { 
    this.activatedroute.queryParams.subscribe((res) => {
      this.partnershipId = res.partnershipId;
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
  this.viewBlog()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'text': new FormControl('', [Validators.required]),
      'url': new FormControl('', [Validators.required]),
      'image': new FormControl('')
    })
  }

  addSwatches() {
    let url = `static/update-partner?partnerId=${this.partnershipId}`
    let data = {
      'text': this.addSwatchesForm.value.text,
      'url': this.addSwatchesForm.value.url,
      'logo' : this.imageUrl,
      "description": "string"
    }
    this.commonService.showSpinner();
    this.commonService.post(url,data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/list-partner'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
   // edit faq english
 viewBlog(){
  this.commonService.showSpinner()
this.commonService.get("static/get-partner-by-id?partnerId="+this.partnershipId).subscribe((res:any)=>{
  if (res.status=200) {
    this.editData=res.data;
    this.commonService.hideSpinner()
this.addSwatchesForm.patchValue({
  text : this.editData.text,
  url : this.editData.url
})
this.imageUrl = this.editData.logo

  }
 },err=>{

   this.commonService.hideSpinner();
   if(err['status']=='401'){
     this.commonService.toasterErr('Unauthorized Access');
   }else{
   this.commonService.toasterErr('Something Went Wrong');
}
 })

} 
   // Image Functionality Start Here
   uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }
}
