import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-view-partnership',
  templateUrl: './view-partnership.component.html',
  styleUrls: ['./view-partnership.component.css']
})
export class ViewPartnershipComponent implements OnInit {
  partnershipId: any;
  skirtListArray: any;
  colorList : any = [];
  editData:any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.partnershipId = res.partnershipId;
    })
    this.viewBlog()
  }

  // edit faq english
 viewBlog(){
  this.commonService.showSpinner()
this.commonService.get("static/get-partner-by-id?partnerId="+this.partnershipId).subscribe((res:any)=>{
  if (res.status=200) {
    this.editData=res.data;
    this.commonService.hideSpinner()

  }
 },err=>{

   this.commonService.hideSpinner();
   if(err['status']=='401'){
     this.commonService.toasterErr('Unauthorized Access');
   }else{
   this.commonService.toasterErr('Something Went Wrong');
}
 })

} 
}
