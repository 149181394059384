import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-nominee-management',
  templateUrl: './nominee-management.component.html',
  styleUrls: ['./nominee-management.component.css']
})
export class NomineeManagementComponent implements OnInit {

  userForm: FormGroup;
  ticketList: any = [];
  itemsPerPage: number = 10;
  referralLimit: any
  totalRecords: any;
  pageNumber: number = 1;
  arr: any = ['RESOLVED', 'INPROGRESS', 'CLOSED', 'CANCELLED']
  updatestatus: string;
  isAssending: boolean = true;
  today: any = new Date().toISOString().slice(0, 16)
  transferForm: FormGroup
  flag: any
  dashStatus: any

  constructor(private router: Router, public service: ServiceService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(res => {
      this.flag = res.flag
      console.log(this.flag);
      if (this.flag == 'approve') {
        this.dashStatus = 'ACTIVE'
        console.log(this.dashStatus);
      } else if (this.flag == 'reject') {
        this.dashStatus = 'REJECTED'
      } else if (this.flag == 'pending') {
        this.dashStatus = 'PENDING'
      }
    })
  }

  ngOnInit() {
    this.searchFormValidation()
    this.lists()
    // this.userForm = new FormGroup({
    //   'startdate': new FormControl('', Validators.required),
    //   'enddate': new FormControl('', Validators.required),
    //   'searchText': new FormControl(''),
    //   'status': new FormControl(''),

    // })

  }
  searchFormValidation() {
    this.transferForm = new FormGroup({
      email: new FormControl(''),
      phoneNo: new FormControl(''),
      nomineeStatus: new FormControl(''),
      sharePercentage: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    })
    if (this.flag == 'approve') {
      this.transferForm.patchValue({
        nomineeStatus: 'ACTIVE'
      })
    } else if (this.flag == 'reject') {
      this.transferForm.patchValue({
        nomineeStatus: 'REJECTED'
      })
    } else if (this.flag == 'pending') {
      this.transferForm.patchValue({
        nomineeStatus: 'PENDING'
      })
    }
  }

  // reset() {
  //   if (
  //     this.transferForm.value.fromDate ||
  //     this.transferForm.value.toDate ||
  //     this.transferForm.value.email ||
  //     this.transferForm.value.phoneNo ||
  //     this.transferForm.value.nomineeStatus ||
  //     this.transferForm.value.sharePercentage
  //   ) {
  //     this.transferForm.reset({
  //       fromDate: "",
  //       toDate: "",
  //       email: "",
  //       phoneNo: "",
  //       nomineeStatus: "",
  //       sharePercentage: "",

  //     });
  //     this.lists();
  //   }
  // }

  reset() {
    this.transferForm.reset({
      fromDate: "",
      toDate: "",
      email: "",
      phoneNo: "",
      nomineeStatus: "",
      sharePercentage: "",
    });
    this.dashStatus = ""
    this.lists();
  }

  pagination(page) {

    this.pageNumber = page
    this.lists()
  }
  planData: any = []
  lists() {
    let url = `account/admin/get-all-nominne-list?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}${this.dashStatus ? "&nomineeStatus=" + this.dashStatus : ""}`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.planData = res['data']['RESULT_LIST']
        this.totalRecords = res['data']['totalCount']
        this.service.hideSpinner();
        // this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }
  viewNominee(nomineeId,userId) {
    // alert(nomineeId)
    this.router.navigate(['/nominee-view'], { queryParams: { nomineeId: nomineeId,userId:userId } })
  }
  disableSearchbtn() {

    if (this.transferForm.value.email || this.transferForm.value.phoneNo || this.transferForm.value.nomineeStatus || this.transferForm.value.sharePercentage ||
      this.transferForm.value.fromDate || this.transferForm.value.toDate
    ) {
      return false
    }
    else {
      return true
    }

  }
  searchNominee() {
    let fromDate = Date.parse(this.transferForm.value.fromDate);
    let toDate = Date.parse(this.transferForm.value.toDate);
    let url = `account/admin/get-all-nominne-list?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.email ? "&email=" + this.transferForm.value.email : ""}${this.transferForm.value.nomineeStatus ? "&nomineeStatus=" + this.transferForm.value.nomineeStatus : ""}${this.transferForm.value.phoneNo ? "&phoneNo=" + this.transferForm.value.phoneNo : ""}${this.transferForm.value.sharePercentage ? "&sharePercentage=" + this.transferForm.value.sharePercentage : ""}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.planData = res['data']['RESULT_LIST']
        this.totalRecords = res['data']['totalCount']
        this.service.hideSpinner();
        // this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }
}
