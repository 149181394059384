import { ServiceService } from 'src/app/service.service';
import { AddAdminComponent } from './../add-admin/add-admin.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.css']
})
export class ReferralComponent implements OnInit {

  tab: any = 'current'
  activePlan: any = " "
  referralLimit: any = " "

  constructor(public service: ServiceService) { }

  ngOnInit() {
    this.getAdminReferral();
    this.getOnboardAmount();
  }

  select(item) {
    this.tab = item
  }
  planData: any
  getAdminReferral() {
    let url = `account/admin/user-management/get-admin-referal`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.planData = res['data']
        this.service.hideSpinner();
        // this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }
  onboardData: any
  getOnboardAmount() {
    let url = `account/admin/user-management/total-onborad-distribute`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.onboardData = res['data']
        console.log(this.onboardData);

        this.service.hideSpinner();
        // this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }

  addReferralActivePlane() {
    let url = `account/admin/user-management/admin-referal-add`
    let data = {
      limit: this.activePlan,
      referalAmountRegister: +this.referralLimit
    }
    this.service.post(url, data).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.getAdminReferral();

        this.service.hideSpinner();
        // this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }
  updateReferralActivePlane() {
    // this.activePlan  = 'this.activePlan'
    let url = `account/admin/user-management/admin-referal-update?limit=${this.activePlan}&referalAmountRegister=${this.referralLimit}`

    this.service.post(url, {}).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.getAdminReferral();

        this.service.hideSpinner();
        // this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }

  // referralDistributionLimit() {
  //   let url = `account/admin/user-management/admin-referal-add`
  //   let data = {
  //     referalAmountRegister: this.referralLimit
  //   }
  //   this.service.post(url, data).subscribe((res: any) => {
  //     if (res['status'] == 200) {
  //       this.getAdminReferral();

  //       this.service.hideSpinner();
  //       this.service.toasterSucc(res.message);
  //     } else {
  //       this.service.hideSpinner();
  //       this.service.toasterErr(res.message)
  //     }
  //   }, (err) => {
  //     this.service.hideSpinner();
  //     this.service.toasterErr(err.error.message);
  //   })
  // }

}
