import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-edit-announcement',
  templateUrl: './edit-announcement.component.html',
  styleUrls: ['./edit-announcement.component.css']
})
export class EditAnnouncementComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  announcementId: any;
  editData: any;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService, public active:ActivatedRoute) { 
    this.active.queryParams.subscribe((res:any)=>{
      this.announcementId=res.announcementId;
    })
   }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.viewBlog()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'title': new FormControl('', [Validators.required]),
      'description': new FormControl('', [Validators.required]),
      'image': new FormControl('')
    })
  }

  addSwatches() {
    let url = `static/update-announcement`
    let data = {
      'title': this.addSwatchesForm.value.title,
      'description': this.addSwatchesForm.value.description,
      'image' : this.imageUrl,
      'announcementId': this.announcementId
    }
    this.commonService.showSpinner();
    this.commonService.post(url,data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/list-announcement'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }


 // edit faq english
 viewBlog(){
    this.commonService.showSpinner()
  this.commonService.get("static/get-announcement-by-id?announcementId="+this.announcementId).subscribe((res:any)=>{
    if (res.status==200) {
      this.editData=res.data;
      this.commonService.hideSpinner()
      this.addSwatchesForm.patchValue({
        'title':this.editData.title,
        'description':this.editData.description
      })
      this.imageUrl = this.editData.image
    }
   },err=>{
  
     this.commonService.hideSpinner();
     if(err['status']=='401'){
       this.commonService.toasterErr('Unauthorized Access');
     }else{
     this.commonService.toasterErr('Something Went Wrong');
  }
   })

 } 
 

   // Image Functionality Start Here
   uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }
}
