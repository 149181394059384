import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-commission-management-list',
  templateUrl: './commission-management-list.component.html',
  styleUrls: ['./commission-management-list.component.css']
})
export class CommissionManagementListComponent implements OnInit {
  coinCommissionList: any = []

  constructor(public service: ServiceService, private datePipe: DatePipe, private router: Router) { }

  ngOnInit() {
    this.getWalletList()
  }

  getWalletList() {
    let apiReqUrl = 'wallet/admin/hot-cold-storage/get-admin-commission-amount'
    // this.service.showSpinner();
    this.service.get(apiReqUrl).subscribe((res) => {
      if (res['status'] == 200) {
        this.coinCommissionList = res['data']
      } else {
        this.coinCommissionList = ''
        this.service.toasterErr('No data found')
      }
    }, err => {
      this.coinCommissionList = ''
      this.service.toasterErr('No data found')
    })
  }

}
