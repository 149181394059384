import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any
@Component({
  selector: 'app-xindia-details-list',
  templateUrl: './xindia-details-list.component.html',
  styleUrls: ['./xindia-details-list.component.css']
})
export class XindiaDetailsListComponent implements OnInit {

  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  holdingId: any;
  today: any = new Date().toISOString().slice(0, 16)

  userStatus: any = 'ACTIVE';

  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getEmbellishmentList();
  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }

  selectStatus() {
    this.currentPage = 1
  }
  // -------------------- get category list --------------------- //
  getEmbellishmentList() {
    let apiReqUrl: any = `static/get-HoldingXindia`
    // this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.status == 200) {
        this.bodyListArray = res.data ? res.data : '';
        this.totalItems = res.data.Count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    },(err)=>{
      if(err.status == 404){
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
      
    })
  }

  pagination(page) {
    this.currentPage = page;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/xindia-tab-add'])
  }
   // view category
   viewBody(holdingId) {
    this.router.navigate(['/xindia-tab-view'], { queryParams: { holdingId: holdingId } })
  }
 
  // edit category
  editBody(holdingId) {
    this.router.navigate(['/xindia-tab-edit'], { queryParams: { holdingId: holdingId } })
  }
  
  // ------------------------------- delete hospital ----------------------------- //
  deleteCategoryModal(holdingId) {
    $('#deleteCategory').modal('show')
    this.holdingId = holdingId
  }
  deleteCategory() {
    let apiReqUrl: any = "static/admin/delete-HoldingXindia?holdListxindiaId="+this.holdingId
    this.commonService.showSpinner();
    this.commonService.delete(apiReqUrl).subscribe((res: any) => {
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.getEmbellishmentList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
}
