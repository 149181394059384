import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';

declare var $
@Component({
  selector: 'app-user-deatils-nominee',
  templateUrl: './user-deatils-nominee.component.html',
  styleUrls: ['./user-deatils-nominee.component.css']
})
export class UserDeatilsNomineeComponent implements OnInit {
  nomineeId: any;
  skirtListArray: any;
  colorList : any = [];
  editData:any
  adminId:any
  rejectionReason:string='';
  userId:any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.nomineeId = res.nomineeId;
      this.userId =res.userId
    })
    this.viewNominee();
    this.adminId = localStorage.getItem('userId')
    // console.log(this.adminId);
    

  }

  profileDataView:any 
// view nominee
viewNominee() {
  let url = `account/get-details-by-nomineeId?nomineeId=${this.nomineeId}`
  this.commonService.showSpinner();
  // this.service.getCandyPixelForms(`account/get-details-by-userId`).subscribe(
    this.commonService.get(url).subscribe(
    (res) => {
      if (res["status"] == 200) {
        this.profileDataView = res["data"];
          this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res["message"]);
      }
    },
    (err) => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err['message']);
    }
  );
}
// back(){
//   user-details?userId=4&email=somya@mailinator.com
// }

approveKyc(){
  this.commonService.showSpinner();
  let data = {
    // "documentId": this.documentIdd,
    // "kycId": this.kycIdd,
    "nomineeId" : this.nomineeId,
    "status": "ACTIVE",
    "userId" : this.userId
  }
  this.commonService.post('account/admin/nominee-approve-reject',data).subscribe((res:any)=>{
    if(res.status == 200 || res.status == 201) {
     
     if (this.profileDataView.nomineeStatus=='ACTIVE') {
        this.commonService.toasterSucc(res.message)
      }
      else{
        this.commonService.toasterSucc(res.message)
      }
      this.commonService.hideSpinner();
      this.viewNominee()
      // this.getParticularKycDetail(this.userKycId)
    }
    else {
      this.commonService.hideSpinner();
    }
  },(error)=>{
    this.commonService.hideSpinner();
  })
}
openModal(){
  $('#reject').modal('show')
}
rejectKyc() {
  $('#reject').modal('hide')
  this.commonService.showSpinner();
  let data = {
    "reason": this.rejectionReason,
    "nomineeId" : this.nomineeId,
    "userId" : this.userId,

    "status": "REJECTED"
  }
  this.commonService.post('account/admin/nominee-approve-reject',data).subscribe((res:any)=>{
    if(res.status == 200) {
      this.commonService.toasterSucc("Successfully Rejected")
      // this.getParticularKycDetail(this.userKycId)
      this.commonService.hideSpinner();
      this.viewNominee()

    }
    else {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(res.message)
    }
  },(error)=>{
    this.commonService.toasterErr('Something went wrong')
    this.commonService.hideSpinner();
  })
}

}
