import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-career-subcategory-add',
  templateUrl: './career-subcategory-add.component.html',
  styleUrls: ['./career-subcategory-add.component.css']
})
export class CareerSubcategoryAddComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  bodyListArray:any  = [];
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getEmbellishmentList()
  
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'subcatName': new FormControl('', [Validators.required]),
      'jobType': new FormControl('', [Validators.required]),
      'category': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'description': new FormControl('', [Validators.required]),
      'requirements': new FormControl('',[Validators.required]),
    })
  }

 
 


  addSwatches() {
    let url = `static/add-sub-category`
    let data = {
      'category': this.addSwatchesForm.value.category,
      'subTeamName': this.addSwatchesForm.value.subcatName,
      'careerType': this.addSwatchesForm.value.jobType,
      'requirement': this.addSwatchesForm.value.requirements,
      'description': this.addSwatchesForm.value.description,
    }
    this.commonService.showSpinner();
    this.commonService.post(url,data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/career-subcat-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
   // Image Functionality Start Here
   uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

  getEmbellishmentList() {
    let apiReqUrl: any = `static/get-all-career`

    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data ? res.data : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    },(err)=>{
      if(err.status == 404){
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
    })
  }
}
