import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-add-announcement',
  templateUrl: './add-announcement.component.html',
  styleUrls: ['./add-announcement.component.css']
})
export class AddAnnouncementComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  tab: any = 'announcement';
  countryList: any = [];
  country: any;
  generalAnnouncementForm: FormGroup
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.genaralF()
    this.getCountryList()
  }

  getCountryList() {
    this.commonService.get('account/get-country-list').subscribe((res) => {
      if (res['status'] == 200) {
        this.countryList = res['data']
      }
    })
  }
  select(tab) {
    this.tab = tab
  }

  genaralF() {
    this.generalAnnouncementForm = new FormGroup({
      country: new FormControl('', [Validators.required]),
      event: new FormControl(''),
      user: new FormControl(''),
      generaldescription: new FormControl('', [Validators.required])
    })
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'title': new FormControl('', [Validators.required]),
      'description': new FormControl('', [Validators.required]),
      'image': new FormControl('')
    })
  }

  addSwatches() {
    let url = `static/add-announcement`
    let data = {
      'title': this.addSwatchesForm.value.title,
      'description': this.addSwatchesForm.value.description,
      'image': this.imageUrl
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/list-announcement'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }
  userList: any = []
  pageNumber: any = 1;
  pageSize: any = 10
  getlist($event) {
    let countryDa = this.generalAnnouncementForm.value.country;
    let url = `account/admin/user-management/filter-user-details?page=${this.pageNumber - 1}&pageSize=${this.pageSize}${this.generalAnnouncementForm.value.country ? "&country=" + countryDa : ""}`;
    // var url = "account/admin/user-management/filter-user-details";
    // var url =
    // "account/admin/user-management/filter-user-details?page=" +
    // (this.pageNumber - 1) +
    // "&pageSize=10";
    // this.commonService.showSpinner();
    this.commonService.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.userList = res['data']['list'];
          this.totalItems = res.data.totalCount;
        }

        this.commonService.hideSpinner();
      },
      (err: any) => {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(err.error.message);
      }
    );
  }
  publishAnnouncement() {
    let url = `account/admin/user-management/notification-send-all-user?country=${this.generalAnnouncementForm.value.country}&notiType=${this.generalAnnouncementForm.value.event}`
    // this.commonService.showSpinner();
    let data = {
      description: this.generalAnnouncementForm.value.generaldescription
    }
    this.commonService.postApi(url, data).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.commonService.hideSpinner();

          // this.userList = res['data']['list'];
          // this.totalItems = res.data.totalCount;
          this.commonService.toasterSucc(res.message)
          this.router.navigate(['/list-announcement'])

        } else {
          this.commonService.toasterErr(res.message)
          this.commonService.hideSpinner();

        }

      },
      (err: any) => {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(err.error.message);
      }
    );

  }
}
