import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.css']
})
export class EditTeamComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  projectTeamId: any;
  teamImage
  editData: any;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  // teamId: any
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.projectTeamId = res.projectTeamId;


    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.viewBlog()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'name': new FormControl('', [Validators.required]),
      'title': new FormControl('', [Validators.required]),
      'description': new FormControl(''),
      'twitterurl': new FormControl(''),
      'linkedinUrl': new FormControl(''),
      'telegramUrl': new FormControl(''),
      'image': new FormControl('')
    })
  }
  addSwatches() {
    let url = `static/update?projectTeamId=${this.projectTeamId}`
    let data = {
      'imageUrl': this.imageUrl,
      'teamName': this.addSwatchesForm.value.name,
      'proffessionType': this.addSwatchesForm.value.title,
      'educationalDetails': this.addSwatchesForm.value.description,
      'instagram': this.addSwatchesForm.value.linkedinUrl,
      'telegram': this.addSwatchesForm.value.telegramUrl,
      'twitter': this.addSwatchesForm.value.twitterurl,
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/list-team'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  // edit faq english
  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.get("static/getById?projectTeamId=" + this.projectTeamId).subscribe((res: any) => {
      if (res.status == 200) {
        console.log('jjh', res);
        this.editData = res.data;
        this.commonService.hideSpinner()
        this.commonService.toasterSucc(res.message);
        setTimeout(() => {
          this.addSwatchesForm.patchValue({
            title: this.editData.proffessionType,
            name: this.editData.teamName,
            description: this.editData.educationalDetails,
            linkedinUrl: this.editData.instagram,
            telegramUrl: this.editData.telegram,
            twitterurl: this.editData.twitter,
          })
        }, 500);
        this.imageUrl = this.editData.imageUrl
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }
  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }
}
